import { AuthModel } from './../../pages/login/Auth';
import { Component, OnInit, ElementRef, OnDestroy } from "@angular/core";
import { ROUTES } from "../sidebar/sidebar.component";
import { Location } from "@angular/common";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from "../../pages/login/login.services";
import { UserService } from 'src/app/pages/user/user.services';
import { filter } from 'rxjs/operators';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  enabled: boolean;
  item?: any;
}
interface RoutesTitle {
  path: string;
  title: string;
}

const ROUTES_TITLE: RoutesTitle[] = [
  {
    path: '/date-eftsec-form',
    title: 'Fecha EFTSEC',
  },
  {
    path: '/key-eftsec-form',
    title: 'Llave EFTSEC',
  },
  {
    path: '/emv-form',
    title: 'EMV',
  },
  {
    path: '/emv-key-form',
    title: 'Llave EMV',
  },
  {
    path: '/emv-app-form',
    title: 'Aplicación EMV',
  },
  {
    path: '/issuer-form',
    title: 'Emisores',
  },
  {
    path: '/ccv-form',
    title: 'CCV',
  },
  {
    path: '/kind-affiliation-form',
    title: 'Tipo de Afiliaciones',
  },
  {
    path: '/kind-pin-form',
    title: 'Tipo de PIN',
  },
  {
    path: '/connection-type-form',
    title: 'Tipo de Conexión',
  },
]
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"]
})
export class NavbarComponent implements OnInit, OnDestroy {
  menuItems: RouteInfo[];
  private listTitles: any[];
  location: Location;
  mobile_menu_visible: any = 0;
  private toggleButton: any;
  private sidebarVisible: boolean;
  currentUser: AuthModel;
  public isCollapsed = true;

  permissions: any = [];
  templateEnabled = false;
  posEnabled = false;
  hostEnabled = false;
  rangeEnabled = false;
  bankEnabled = false;
  tableEnabled = false;
  userEnabled = false;
  auditEnabled = false;
  updatePosEnabled = false;

  routes = ROUTES;

  closeResult: string;

  constructor(
    location: Location,
    private userService: UserService,
    private route: ActivatedRoute,
    private element: ElementRef,
    private router: Router,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService
  ) {
    this.location = location;
    this.sidebarVisible = false;
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {

  var navbar = document.getElementsByClassName('navbar')[0];
    if (window.innerWidth < 993 && !this.isCollapsed) {
      navbar.classList.add('bg-white');
      navbar.classList.remove('navbar-transparent');
    } else {
      navbar.classList.remove('bg-white');
      navbar.classList.add('navbar-transparent');
    }
  }

  ngOnInit() {
    window.addEventListener("resize", this.updateColor);
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    this.router.events.subscribe(event => {
      this.sidebarClose();
      var $layer: any = document.getElementsByClassName("close-layer")[0];
      if ($layer) {
        $layer.remove();
        this.mobile_menu_visible = 0;
      }
    });

    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.loadPermissions();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.loadPermissions();
    });
  }

  loadPermissions() {
    this.userService.getActualUserPermission().subscribe(
      (lst) => {
        this.permissions = lst['data'].permissions;

        if (lst['data'].role == 'superadmin') {
          this.templateEnabled = true;
          this.userEnabled = true;
          this.bankEnabled = true;
          this.hostEnabled = true
          this.posEnabled = true;
          this.tableEnabled = true;
          this.rangeEnabled = true;
          this.auditEnabled = true;
          this.updatePosEnabled = true;
        } else {
          this.permissions.map((data: any) => {

            if (data.module == 'PLANTILLAS' && data.description == 'Listar Plantillas') {
              this.templateEnabled = true;
            }

            if (data.module == 'USUARIOS' && data.description == 'Listar Usuarios') {
              this.userEnabled = true;
            }

            if (data.module == 'BANCOS' && data.description == 'Listar Bancos') {
              this.bankEnabled = true;
            }

            if (data.module == 'POS' && data.description == 'Listar POS') {
              this.posEnabled = true;
            }

            if (data.module == 'TABLAS' && data.description == 'Listar Hosts') {
              this.hostEnabled = true
            }

            if (data.module == 'TABLAS' && data.description == 'Listar Rangos') {
              this.rangeEnabled = true;
            }

            if (data.module == 'LOGS' && data.description == 'Listar logs') {
              this.auditEnabled = true;
            }

            if (data.module == 'APPS' && data.description == 'Listar Aplicativos') {
              this.updatePosEnabled = true;
            }


            if (
                data.description == 'Listar Hosts' ||
                data.description == 'Listar Fechas EFTSec' ||
                data.description == 'Listar Llaves EFTSec' ||
                data.description == 'Listar Aplicación EMV' ||
                data.description == 'Listar EMV' ||
                data.description == 'Listar Llaves EMV' ||
                data.description == 'Listar Tipos de Afiliación' ||
                data.description == 'Listar Entrada CVVs' ||
                data.description == 'Listar Emisores' ||
                data.description == 'Listar Tipos de PIN' ||
                data.description == 'Listar Tipos de Conexión' ||
                data.description == 'Listar Entrada de Datos' ||
                data.description == 'Listar Tipos de Servicios' ||
                data.description == 'Listar Hosts'
            ) {
              this.tableEnabled = true;
            }
          })
        }
        const tmpRoutes = this.routes;

        this.routes.map((el, index) => {
          if (el.title == 'Plantilla') {
            tmpRoutes[index].enabled = this.templateEnabled
          }

          if (el.title == 'Perfil de usuarios') {
            tmpRoutes[index].enabled = this.userEnabled
          }

          if (el.title == 'Banco') {
            tmpRoutes[index].enabled = this.bankEnabled
          }

          if (el.title == 'POS') {
            tmpRoutes[index].enabled = this.posEnabled
          }

          if (el.title == 'Host') {
            tmpRoutes[index].enabled = this.hostEnabled
          }

          if (el.title == 'Rango') {
            tmpRoutes[index].enabled = this.rangeEnabled
          }

          if (el.title == 'Tablas') {
            tmpRoutes[index].enabled = this.tableEnabled
          }

          if (el.title == 'Actualización POS') {
            tmpRoutes[index].enabled = this.updatePosEnabled
          }

          if (el.title == 'Auditoria') {
            tmpRoutes[index].enabled = this.auditEnabled
          }
        })

        this.routes = tmpRoutes;
      }
    )
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName("nav")[0];
    if (!this.isCollapsed) {
      navbar.classList.remove("navbar-transparent");
      navbar.classList.add("bg-white");
    } else {
      navbar.classList.add("navbar-transparent");
      navbar.classList.remove("bg-white");
    }
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );
    const html = document.getElementsByTagName("html")[0];
    if (window.innerWidth < 991) {
      mainPanel.style.position = "fixed";
    }

    setTimeout(function() {
      toggleButton.classList.add("toggled");
    }, 500);

    html.classList.add("nav-open");

    this.sidebarVisible = true;
  }
  sidebarClose() {
    const html = document.getElementsByTagName("html")[0];
    this.toggleButton.classList.remove("toggled");
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );

    if (window.innerWidth < 991) {
      setTimeout(function() {
        mainPanel.style.position = "";
      }, 500);
    }
    this.sidebarVisible = false;
    html.classList.remove("nav-open");
  }
  sidebarToggle() {
    // const toggleButton = this.toggleButton;
    // const html = document.getElementsByTagName('html')[0];
    var $toggle = document.getElementsByClassName("navbar-toggler")[0];

    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
    const html = document.getElementsByTagName("html")[0];

    if (this.mobile_menu_visible == 1) {
      // $('html').removeClass('nav-open');
      html.classList.remove("nav-open");
      if ($layer) {
        $layer.remove();
      }
      setTimeout(function() {
        $toggle.classList.remove("toggled");
      }, 400);

      this.mobile_menu_visible = 0;
    } else {
      setTimeout(function() {
        $toggle.classList.add("toggled");
      }, 430);

      var $layer = document.createElement("div");
      $layer.setAttribute("class", "close-layer");

      if (html.querySelectorAll(".main-panel")) {
        document.getElementsByClassName("main-panel")[0].appendChild($layer);
      } else if (html.classList.contains("off-canvas-sidebar")) {
        document
          .getElementsByClassName("wrapper-full-page")[0]
          .appendChild($layer);
      }

      setTimeout(function() {
        $layer.classList.add("visible");
      }, 100);

      $layer.onclick = function() {
        //asign a function
        html.classList.remove("nav-open");
        this.mobile_menu_visible = 0;
        $layer.classList.remove("visible");
        setTimeout(function() {
          $layer.remove();
          $toggle.classList.remove("toggled");
        }, 400);
      }.bind(this);

      html.classList.add("nav-open");
      this.mobile_menu_visible = 1;
    }
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }
    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "";
  }

  open(content) {
    this.modalService.open(content, {windowClass: 'modal-search'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  ngOnDestroy(){
     window.removeEventListener("resize", this.updateColor);
  }


  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }


}
