import { Injectable } from '@angular/core';
@Injectable()
export class Constants {
    private static baseUrl: string = 'https://apiqa.fastpayment.3dlinkweb.com/api/'
    //private static baseUrl: string = 'http://localhost:8080/api/'
    public static currenciesUrl: string = Constants.baseUrl + 'configs/currencies'
    public static loginUrl: string = Constants.baseUrl + 'auth/login'
    public static login2FAUrl: string = Constants.baseUrl + 'auth/login2fa'
    public static logout: string = Constants.baseUrl + 'auth/logout';
    public static refreshToken: string = Constants.baseUrl + 'auth/refresh_token';

    public static forgotUrl: string = Constants.baseUrl + 'users/passwordRecovery'
    public static changeUrl: string = Constants.baseUrl + 'users/updatePassword'
    public static usersBaseUrl: string = Constants.baseUrl + 'users'
    public static usersEditBaseUrl: string = Constants.baseUrl + 'users/edit'
    public static usersDeleteBaseUrl: string = Constants.baseUrl + 'users/delete'
    public static posBaseUrl: string = Constants.baseUrl + 'pos'
    public static posNotificacionUrl: string = Constants.baseUrl + 'pos/sendNotification';
    public static posUpdateKey: string = Constants.baseUrl + 'pos/sendKeyNotification';
    public static banksBaseUrl: string = Constants.baseUrl + 'banks'

    public static hostBaseUrl: string = Constants.baseUrl + 'configs/hosts'
    public static hostEditUrl: string = Constants.baseUrl + 'configs/hosts/edit';
    public static hostDeleteUrl: string = Constants.baseUrl + 'configs/hosts/delete';

    public static connectionTypesBaseUrl: string = Constants.baseUrl + 'configs/connectionTypes'
    public static connectionTypesEditUrl: string = Constants.baseUrl + 'configs/connectionTypes/edit'
    public static connectionTypesDeleteUrl: string = Constants.baseUrl + 'configs/connectionTypes/delete'

    public static emvAppsBaseUrl: string = Constants.baseUrl + 'configs/emvApps'

    public static affiliationTypesBaseUrl: string = Constants.baseUrl + 'configs/affiliationTypes'
    public static affiliationTypesEditUrl: string = Constants.baseUrl + 'configs/affiliationTypes/edit'
    public static affiliationTypesDeleteUrl: string = Constants.baseUrl + 'configs/affiliationTypes/delete'

    public static rangesBaseUrl: string = Constants.baseUrl + 'ranges'
    public static rangesEditUrl: string = Constants.baseUrl + 'ranges/edit'
    public static rangesDeleteUrl: string = Constants.baseUrl + 'ranges/delete'

    public static dataEntriesBaseUrl: string = Constants.baseUrl + 'configs/dataEntries'
    public static dataEntriesEditUrl: string = Constants.baseUrl + 'configs/dataEntries/edit'
    public static dataEntriesDeleteUrl: string = Constants.baseUrl + 'configs/dataEntries/delete'

    public static templatesBaseUrl: string = Constants.baseUrl + 'templates'
    public static templatesEditBaseUrl: string = Constants.baseUrl + 'templates/edit'
    public static templateCommunicationBaseUrl: string = Constants.baseUrl + 'templates/communication/{idTemplate}'
    public static templateCommunicationEditBaseUrl: string = Constants.baseUrl + 'templates/communication/edit'
    public static templateTransactionBaseUrl: string = Constants.baseUrl + 'templates/transaction/{idTemplate}'
    public static templateTransactionEditBaseUrl: string = Constants.baseUrl + 'templates/transaction/edit'
    public static templateRangeBaseUrl: string = Constants.baseUrl + 'templates/range/{idTemplate}'
    public static templateRangeEditBaseUrl: string = Constants.baseUrl + 'templates/range/edit'
    public static templateRangeDeleteBaseUrl: string = Constants.baseUrl + 'templates/range/delete'
    public static templateMassiveUpdateBaseUrl: string = Constants.baseUrl + 'templates/massiveNotifications';

    public static templateDeleteUrl: string = Constants.baseUrl + 'templates/delete';
    public static dateEftesecBaseUrl: string = Constants.baseUrl + 'eftsec/dates'
    public static dateEftsecDeleteUrl: string = Constants.baseUrl + 'eftsec/dates/delete';
    public static dateEftsecEditUrl: string = Constants.baseUrl + 'eftsec/dates/edit';
    public static dateKeyBaseUrl: string = Constants.baseUrl + 'eftsec/keys'
    public static dateKeyDeleteUrl: string = Constants.baseUrl + 'eftsec/keys/delete';
    public static dateKeyEditUrl: string = Constants.baseUrl + 'eftsec/keys/edit';

    public static emvBaseUrl: string = Constants.baseUrl + 'emvs';
    public static emvEditUrl: string = Constants.baseUrl + 'emvs/edit';
    public static emvDeleteUrl: string = Constants.baseUrl + 'emvs/delete';

    public static keyEmvBaseUrl: string = Constants.baseUrl + 'emvs/keys';
    public static keyEmvEditUrl: string = Constants.baseUrl + 'emvs/keys/edit';
    public static keyEmvDeleteUrl: string = Constants.baseUrl + 'emvs/keys/delete';

    public static appEmvBaseUrl: string = Constants.baseUrl + 'emvs/apps';
    public static appEmvEditUrl: string = Constants.baseUrl + 'emvs/apps/edit';
    public static appEmvDeleteUrl: string = Constants.baseUrl + 'emvs/apps/delete';

    public static issuerBaseUrl: string = Constants.baseUrl + 'configs/issuers';
    public static issuerEditUrl: string = Constants.baseUrl + 'configs/issuers/edit';
    public static issuerDeleteUrl: string = Constants.baseUrl + 'configs/issuers/delete';

    public static kindPinBaseUrl: string = Constants.baseUrl + 'configs/pin_types';
    public static kindPinEditUrl: string = Constants.baseUrl + 'configs/pin_types/edit';
    public static kindPinDeleteUrl: string = Constants.baseUrl + 'configs/pin_types/delete';

    public static cvvBaseUrl: string = Constants.baseUrl + 'configs/input_cvv';
    public static cvvEditUrl: string = Constants.baseUrl + 'configs/input_cvv/edit';
    public static cvvDeleteUrl: string = Constants.baseUrl + 'configs/input_cvv/delete';

    public static serviceTypeBaseUrl: string = Constants.baseUrl + 'configs/service_types';
    public static serviceTypeEditUrl: string = Constants.baseUrl + 'configs/service_types/edit';
    public static serviceTypeDeleteUrl: string = Constants.baseUrl + 'configs/service_types/delete';

    public static currenciesBaseUrl: string = Constants.baseUrl + 'configs/currencies';
    public static currenciesEditUrl: string = Constants.baseUrl + 'configs/currencies/edit';
    public static currenciesDeleteUrl: string = Constants.baseUrl + 'configs/currencies/delete';

    public static privilegesBaseUrl: string = Constants.baseUrl + 'permissions/urls';
    public static privilegesRoleUrl: string = Constants.baseUrl + 'permissions/role';

    public static rolesBaseUrl: string = Constants.baseUrl + 'configs/roles';

    public static userPermissionBaseUrl: string = Constants.baseUrl + 'permissions/user';

    public static auditBaseUrl: string = Constants.baseUrl + 'logs';

    public static appBaseUrl: string = Constants.baseUrl + 'apps';
    public static appAddPos: string = Constants.baseUrl + 'apps/versions/add_pos';

    public static imagesBaseUrl: string = Constants.baseUrl + 'images';
    public static imagesEditUrl: string = Constants.baseUrl + 'images/edit';
    public static imagesDeleteUrl: string = Constants.baseUrl + 'images/delete';
}
